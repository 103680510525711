/*
=========================================================
* VeriFind - v1.0.0
=========================================================
*/

import React, { useState } from "react";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Input,
} from "reactstrap";
import { searchForVerifiedUser } from "../graphql/queries";
import Loading from "./Loading";
import User from "./User";

const widgetCode =
  // eslint-disable-next-line no-multi-str
  "<script type='text/javascript' src='http://assets.doorkeeper.jp/assets/widget.js'></script> \n \
  <a class='doorkeeper-registration-widget' href='http://apicommunity.doorkeeper.jp/events/773'>Register for Javascript Widget Demo</a> \
";

const reduceVerifiedUser = (verifiedUser) => {
  return {
    id: verifiedUser?.id,
    status: verifiedUser?.status,
    address: verifiedUser?.address,
    member_since: verifiedUser?.member_since,
    contact: verifiedUser?.contact,
    email: verifiedUser?.email,
    name: verifiedUser?.name,
    profile_picture: verifiedUser?.profile_picture,
    organizationID: verifiedUser?.organizationID,
    organization_id: verifiedUser?.organization_id,
  };
};

const VerifyPortalSearchConsole = ({
  setVerifiedUser,
  verifedUser,
  setUserProfile,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const setStateField = (fieldKey, newValue) => {
    setVerifiedUser({
      ...verifedUser,
      ...{
        [fieldKey]: newValue,
      },
    });
  };

  const errorEmptyContent = t("verifyPortal.provideValidID");

  const searchForVerifiedUserCallback = async () => {
    if (!verifedUser?.id || !verifedUser?.name) {
      setError(errorEmptyContent);
      return;
    }

    try {
      setIsLoading(true);
      const gqlSearchInput = {
        organization_id: verifedUser.id,
        name: String(verifedUser.name),
      };
      try {
        await Auth.currentSession();
      } catch (noUserError) {
        console.log('No user session, using ghost user with no access');
        await Auth.signIn("morgan@stockalarm.io", "RZMyLv-tv-qMn2yet9ThYpLY");
      }
      // console.log(currentSession);
      const verifiedUserPayload = await API.graphql(
        graphqlOperation(searchForVerifiedUser, gqlSearchInput)
      );
      const newVerifiedUser = verifiedUserPayload?.data?.searchForVerifiedUser;
      if (newVerifiedUser && Object.keys(newVerifiedUser).length > 0) {
        setError(null);
        setVerifiedUser(null);
        setUserProfile(reduceVerifiedUser(newVerifiedUser));
        setIsLoading(false);
        const currentUser = await Auth.currentUserInfo();
        const isGhostLogin = currentUser.attributes.email === 'morgan@stockalarm.io';
        if (isGhostLogin) {
          console.log('Signing out ghost user');
          await Auth.signOut();
        }
        return;
      }
    } catch (e) {
      console.log(e);
      // pass, assume error
    } finally {
      setVerifiedUser(null);
      setError(t("verifyPortal.notFound"));
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Row>
        <Col md="12">
          <CardHeader>
            <CardTitle tag="h3">
              {t("dashboardScreen.verifyPartnershipText")}
            </CardTitle>
          </CardHeader>
        </Col>
      </Row>
      <Row>
        <Col md="6" style={{ marginLeft: 18 }}>
          <FormGroup>
            <label htmlFor="emailInput">{t("dashboardScreen.IDtext")}</label>
            <Input
              style={{ touchAction: "none" }}
              placeholder="ID"
              type="ID"
              onChange={(e) => setStateField("id", `${e.target.value}`)}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="nameInput">{t("dashboardScreen.nameText")}</label>
            <Input
              style={{ touchAction: "none" }}
              placeholder={t("dashboardScreen.nameText")}
              type="name"
              onChange={(e) => setStateField("name", `${e.target.value}`)}
            />
          </FormGroup>
          {error && <p className="text-danger"> {error} </p>}
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <div className="update">
            <Button
              className="my-1"
              color="primary"
              type="button"
              style={{ backgroundColor: "#6f9eed", float: "right" }}
              onClick={searchForVerifiedUserCallback}
            >
              {t("dashboardScreen.searchText")}
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

const VerifySearchPortalUserProfile = ({ userProfile }) => {
  return <User userProfile={userProfile} />;
};

const VerifyPortal = () => {
  const [verifedUser, setVerifiedUser] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const { t } = useTranslation();

  return (
    <>
      {!userProfile && (
        <Col md="8">
          <Card>
            <CardBody>
              <VerifyPortalSearchConsole
                setVerifiedUser={setVerifiedUser}
                verifedUser={verifedUser}
                setUserProfile={setUserProfile}
              />
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats">
                <i className="fa fa-lock" />{" "}
                {t("dashboardScreen.secureSearchText")}
              </div>
            </CardFooter>
          </Card>
        </Col>
      )}
      <Col md="16" style={{ marginLeft: 12 }}>
        {userProfile && (
          <VerifySearchPortalUserProfile userProfile={userProfile} />
        )}
      </Col>
    </>
  );
};

export default VerifyPortal;
