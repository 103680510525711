/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const forceDeleteAdmin = /* GraphQL */ `
  mutation ForceDeleteAdmin($id: ID!) {
    forceDeleteAdmin(id: $id)
  }
`;
export const forceDeleteVerifiedUser = /* GraphQL */ `
  mutation ForceDeleteVerifiedUser($id: ID!) {
    forceDeleteVerifiedUser(id: $id)
  }
`;
export const createVerifiedUser = /* GraphQL */ `
  mutation CreateVerifiedUser(
    $input: CreateVerifiedUserInput!
    $condition: ModelVerifiedUserConditionInput
  ) {
    createVerifiedUser(input: $input, condition: $condition) {
      id
      organization_id
      name
      member_since
      status
      expertise_level
      attributes
      email
      organizationID
      address
      contact
      profile_picture
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateVerifiedUser = /* GraphQL */ `
  mutation UpdateVerifiedUser(
    $input: UpdateVerifiedUserInput!
    $condition: ModelVerifiedUserConditionInput
  ) {
    updateVerifiedUser(input: $input, condition: $condition) {
      id
      organization_id
      name
      member_since
      status
      expertise_level
      attributes
      email
      organizationID
      address
      contact
      profile_picture
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteVerifiedUser = /* GraphQL */ `
  mutation DeleteVerifiedUser(
    $input: DeleteVerifiedUserInput!
    $condition: ModelVerifiedUserConditionInput
  ) {
    deleteVerifiedUser(input: $input, condition: $condition) {
      id
      organization_id
      name
      member_since
      status
      expertise_level
      attributes
      email
      organizationID
      address
      contact
      profile_picture
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAdminUser = /* GraphQL */ `
  mutation CreateAdminUser(
    $input: CreateAdminUserInput!
    $condition: ModelAdminUserConditionInput
  ) {
    createAdminUser(input: $input, condition: $condition) {
      id
      name
      email
      is_verified
      organizationID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAdminUser = /* GraphQL */ `
  mutation UpdateAdminUser(
    $input: UpdateAdminUserInput!
    $condition: ModelAdminUserConditionInput
  ) {
    updateAdminUser(input: $input, condition: $condition) {
      id
      name
      email
      is_verified
      organizationID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAdminUser = /* GraphQL */ `
  mutation DeleteAdminUser(
    $input: DeleteAdminUserInput!
    $condition: ModelAdminUserConditionInput
  ) {
    deleteAdminUser(input: $input, condition: $condition) {
      id
      name
      email
      is_verified
      organizationID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      name
      org_admin_email
      verifiedUsers {
        items {
          id
          organization_id
          name
          member_since
          status
          expertise_level
          attributes
          email
          organizationID
          address
          contact
          profile_picture
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      adminUsers {
        items {
          id
          name
          email
          is_verified
          organizationID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      name
      org_admin_email
      verifiedUsers {
        items {
          id
          organization_id
          name
          member_since
          status
          expertise_level
          attributes
          email
          organizationID
          address
          contact
          profile_picture
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      adminUsers {
        items {
          id
          name
          email
          is_verified
          organizationID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      name
      org_admin_email
      verifiedUsers {
        items {
          id
          organization_id
          name
          member_since
          status
          expertise_level
          attributes
          email
          organizationID
          address
          contact
          profile_picture
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      adminUsers {
        items {
          id
          name
          email
          is_verified
          organizationID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
