/*!

=========================================================
* VeriFind - v1.0.0
=========================================================

*/
import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { Nav, Row } from "reactstrap";
import PerfectScrollbar from "perfect-scrollbar";
import ls from "local-storage";

import logo from "../../assets/img/logov0.png";
import { API, graphqlOperation } from "aws-amplify";
import { getOrganization } from "graphql/queries";

var ps;

function Sidebar(props) {
  const sidebar = useRef();
  const [companyName, setCompanyName] = useState();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });

  useEffect(() => {
    const effect = async () => {
      const orgData = await API.graphql(graphqlOperation(getOrganization, { id: ls.get('organization_id') }));
      setCompanyName(orgData?.data?.getOrganization?.name)
    };
    effect();
  });


  const name = ls.get("name");
  return (
    <div
      className="sidebar"
      data-color={props.bgColor}
      data-active-color={props.activeColor}
    >
      <div className="logo">
        <a
          href="https://www.verifind.us"
          className="simple-text logo-img"
        >
          <div className="logo-img">
            <img src={logo} alt="verifind-logo" />
          </div>
        </a>
        <a
          href="https://www.verifind.us"
          className="simple-text logo-normal"
        >
          <Row className="justify-content-center" style={{textTransform: 'none'}}>
            {name}
          </Row>
          <Row className="justify-content-center" style={{fontSize: 'xx-small', paddingBottom: 0}}>
          {companyName} {' '} Admin Account
          </Row>
        </a>
      </div>

      <div className="sidebar-wrapper" ref={sidebar}>
        <Nav>
          {props.routes.map((prop, key) => {
            return (
              <li
                className={
                  activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                }
                key={key}
              >
                <NavLink
                  to={prop.layout + prop.path}
                  className="nav-link"
                  activeClassName="active"
                >
                  <i className={prop.icon} />
                  <p>{prop.name}</p>
                </NavLink>
              </li>
            );
          })}
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
