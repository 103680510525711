/*!

=========================================================
* VeriFind - v1.0.0
=========================================================

*/

import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  CardTitle,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
} from "reactstrap";
import { API, graphqlOperation } from "aws-amplify";
import EditableTable from '../components/EditableTable';
import { DataTypes } from '../components/EditableTable/utils';
import Loading from '../views/Loading';
import { listAdminUsers } from '../graphql/queries';
import { useTranslation } from "react-i18next";
import { createAdminUser, forceDeleteAdmin } from "../graphql/mutations";
import { ActionTypes } from '../components/EditableTable/utils';
import removeItemFromArray from '../helpers/utility';
import ls from "local-storage";

const FormModal = ({ className, isVisible, setVisible, addAdmin, setName, setEmail, isLoading, t }) => {
  const toggle = () => setVisible(!isVisible);
  const spinner =
    <span className="btn-neutral">
      <img
        alt="Loading"
        style={{ width: 600, margin: 0, padding: 0 }}
        src={
          require("../assets/img/icons/common/loadingbaloon.gif").default
        }
      />
    </span>;

  return (
    <div>
      <Modal isOpen={isVisible} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle} style={{ marginBottom: 0, paddingBottom: 0 }}>{t("adminScreen.addEmail")}
        </ModalHeader>
        {isLoading ? spinner : <ModalBody style={{ marginBottom: 0, paddingBottom: 0 }}>
          <i className="text-muted" style={{ padding: 10, paddingLeft: 0 }}> {t("adminScreen.userAdminAccess")} </i>
          < hr />
          <FormGroup>
            <label>
              {t("adminScreen.name")}
            </label>
            <Input placeholder= {t("adminScreen.name")} type="name" onChange={(e) => setName(`${e.target.value}`)} />
            < br />

            <label>
              {t("adminScreen.email")}
            </label>
            <Input placeholder="Email" type="email" onChange={(e) => setEmail(`${e.target.value}`)} />
          </FormGroup>
        </ModalBody>
        }

        <ModalFooter>
          <Button color="primary" onClick={addAdmin}>{t("adminScreen.whitelistAdmin")}</Button>{' '}
          <Button color="secondary" onClick={toggle}>{t("adminScreen.cancel")}</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

const adminUserData = (adminUsers, t) => {
  let data = [];
  adminUsers.forEach(adminUser => {
    let row = {
      email: adminUser.email,
      name: adminUser.name,
      action: t("adminScreen.delete"),
    };
    data.push(row);
  });

  let rowActionOptions = [];
  rowActionOptions.push({ label: t("adminScreen.delete"), backgroundColor: 'red' });

  let columns = [
    {
      id: 'email',
      label: 'Email',
      accessor: 'email',
      width: 500,
      dataType: DataTypes.TEXT,
      options: [],
    },
    {
      id: 'name',
      label: t("adminScreen.name"),
      accessor: 'name',
      minWidth: 500,
      dataType: DataTypes.TEXT,
      options: [],
    },
    {
      id: 'action',
      label: t("adminScreen.action"),
      accessor: 'action',
      minWidth: 200,
      dataType: DataTypes.SELECT,
      options: rowActionOptions,
    },
  ];
  return { columns: columns, data: data, skipReset: false };
};

const reduceAdminUsers = (data, userId) => {
  return data?.data?.listAdminUsers?.items?.filter((adminUser)=> adminUser?.id !== userId).map((adminUser) => {
    return {
      id: adminUser.id,
      email: adminUser.email,
      name: adminUser.name,
      createdAt: adminUser.createdAt,
    }
  });
};

const addAdminUser = (newAdminName, newAdminEmail, setIsLoading, setIsVisible) => async () => {
  const organizationId = ls.get('organization_id');
  setIsLoading(true);
  await API.graphql(graphqlOperation(createAdminUser, { input: { email: String(newAdminEmail).toLowerCase(), name: newAdminName, organizationID: organizationId } }));
  setIsVisible(false);
  setIsLoading(false);
};

const ManageAdminUsers = () => {
  const { t } = useTranslation();
  const [adminUsers, setAdminUsers] = useState([]);
  const [isVisible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newAdminName, setNewAdminName] = useState("");
  const [newAdminEmail, setNewAdminEmail] = useState("");
  const [deletedAdmin, setDeletedAdmin] = useState(null);
  const userId = ls.get('id');

  useEffect(() => {
    const fetchAdminUsers = async () => {
      const organizationId = ls.get('organization_id');
      const adminUserPayload = await API.graphql(graphqlOperation(listAdminUsers, { filter: { organizationID: { eq: organizationId } } }));
      const adminUsers = reduceAdminUsers(adminUserPayload, userId);
      setAdminUsers(adminUsers);
    };
    fetchAdminUsers();
  }, [isVisible]);


  useEffect(() => {
    if (!deletedAdmin || !adminUsers.includes(deletedAdmin)) {
      return;
    }

    const deleteAdminUserHandler = async () => {
      setIsLoading(true);
      try {
        const response = await API.graphql(graphqlOperation(forceDeleteAdmin, { id: deletedAdmin.id }));
        if (response) {
          let arrayWithItemRemoved = removeItemFromArray(adminUsers, deletedAdmin);
          setAdminUsers(arrayWithItemRemoved);
          setDeletedAdmin(null);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };
    if (deletedAdmin) {
      deleteAdminUserHandler();
    }
  }, [adminUsers, deletedAdmin]);


  const handlers = {
    [ActionTypes.DELETE_ROW]: (data) => {
      setTimeout(() => {
        const targetAdminUser = adminUsers[data.rowIndex];
        setDeletedAdmin(targetAdminUser);
      }, 10);
    }
  };

  return (
    <>
      <FormModal
        isVisible={isVisible}
        setVisible={setVisible}
        addAdmin={addAdminUser(newAdminName, newAdminEmail, setIsLoading, setVisible)}
        setName={setNewAdminName}
        setEmail={setNewAdminEmail}
        isLoading={isLoading}
        t={t}
      />
      <div className="content">
        <Row key={'main-' + isLoading + adminUsers?.length}>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4"> {t("adminScreen.administrators")} </CardTitle>
                <p className="card-category">
                  {t("adminScreen.adminPrivileges")}
                </p>
                <Button className="my-4" color="primary" type="button" onClick={() => setVisible(true)}>
                  {t("adminScreen.addAdmin")} <i className="ni ni-paper-diploma px-1" />
                </Button>
              </CardHeader>
              <CardBody style={{ height: '75vh' }} className="content-center justify-content-center align-content-center">
                {isLoading ? <Loading height='20vh' /> : <EditableTable handlers={handlers} key={'tableload-' + adminUsers.length} style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }} data={adminUserData(adminUsers, t)} />}
                {adminUsers.length === 0 && <i style={{textAlign: 'center'}}> You have not added any admins to manage your organization! </i>}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ManageAdminUsers;
