import React from "react";
import Amplify, { Hub, Auth } from "aws-amplify";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import ls from "local-storage";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.0.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "assets/nucleo/css/nucleo.css";

import AdminLayout from "layouts/Admin.js";
import VerifyPortal from "views/VerifyPortal.js";
import AuthLayout from "layouts/Auth.js";

import { InMemoryCache, ApolloProvider } from "@apollo/client";
import awsConfig from "./aws-exports";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [productionRedirectSignIn, localRedirectSignIn] =
  awsConfig.oauth.redirectSignIn.split(",");

const [productionRedirectSignOut, localRedirectSignOut] =
  awsConfig.oauth.redirectSignOut.split(",");

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : productionRedirectSignIn,
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : productionRedirectSignOut,
  },
  API: {
    graphql_headers: async () => {
      const session = await Auth.currentSession();
      let headers = {};
      headers["Authorization"] = session?.getAccessToken()?.getJwtToken();
      return headers;
    },
  },
};

Amplify.configure(updatedAwsConfig);

Hub.listen("auth", (data) => {
  const { payload } = data;
  if (payload?.event?.includes("failure")) {
    ls.clear();
    Auth.signOut();
  }
});

const App = () => {
  const client = new AWSAppSyncClient(
    {
      disableOffline: true,
      url: updatedAwsConfig.aws_appsync_graphqlEndpoint,
      region: updatedAwsConfig.aws_appsync_region,
      auth: {
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        jwtToken: async () =>
          (await Auth.currentSession())?.getIdToken()?.getJwtToken(),
      },
    },
    { cache: new InMemoryCache() }
  );

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Switch>
          <Route path={"/verify"} component={VerifyPortal} key={"verify"} />
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Redirect to="/auth/login" />
        </Switch>
      </BrowserRouter>
    </ApolloProvider>
  );
};

export default App;
