import ls from "local-storage";
import { Auth } from "aws-amplify";
import { useEffect } from "react";

import Loading from "./Loading";

const Logout = () => {
  useEffect(() => {
    ls.clear();
    setTimeout(()=> window.location = "/auth/login", 1000)
    Auth.signOut();
  }, []);

  return <Loading />;
};

export default Logout;
