/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const searchForVerifiedUser = /* GraphQL */ `
  query SearchForVerifiedUser($organization_id: String, $name: String) {
    searchForVerifiedUser(organization_id: $organization_id, name: $name) {
      id
      organization_id
      name
      member_since
      status
      expertise_level
      attributes
      email
      organizationID
      address
      contact
      profile_picture
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const syncVerifiedUsers = /* GraphQL */ `
  query SyncVerifiedUsers(
    $filter: ModelVerifiedUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVerifiedUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        organization_id
        name
        member_since
        status
        expertise_level
        attributes
        email
        organizationID
        address
        contact
        profile_picture
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getVerifiedUser = /* GraphQL */ `
  query GetVerifiedUser($id: ID!) {
    getVerifiedUser(id: $id) {
      id
      organization_id
      name
      member_since
      status
      expertise_level
      attributes
      email
      organizationID
      address
      contact
      profile_picture
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listVerifiedUsers = /* GraphQL */ `
  query ListVerifiedUsers(
    $filter: ModelVerifiedUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVerifiedUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organization_id
        name
        member_since
        status
        expertise_level
        attributes
        email
        organizationID
        address
        contact
        profile_picture
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAdminUser = /* GraphQL */ `
  query GetAdminUser($id: ID!) {
    getAdminUser(id: $id) {
      id
      name
      email
      is_verified
      organizationID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listAdminUsers = /* GraphQL */ `
  query ListAdminUsers(
    $filter: ModelAdminUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        is_verified
        organizationID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAdminUsers = /* GraphQL */ `
  query SyncAdminUsers(
    $filter: ModelAdminUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        email
        is_verified
        organizationID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      org_admin_email
      verifiedUsers {
        items {
          id
          organization_id
          name
          member_since
          status
          expertise_level
          attributes
          email
          organizationID
          address
          contact
          profile_picture
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      adminUsers {
        items {
          id
          name
          email
          is_verified
          organizationID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        org_admin_email
        verifiedUsers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adminUsers {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOrganizations = /* GraphQL */ `
  query SyncOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrganizations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        org_admin_email
        verifiedUsers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adminUsers {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
