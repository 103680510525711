/*!

=========================================================
* VeriFind - v1.0.0
=========================================================
*/

import { getStatusOptionColor } from 'helpers/status'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, CardBody, FormGroup, Input, Row, Col } from 'reactstrap'
import Badge from '../components/EditableTable/Badge'

const IGNORED_KEYS = ['organizationID', 'id']

const wrapWithRow = (child, index) => (
  <Row className="my-auto mx-auto" key={String(index)}>
    {child}
  </Row>
)

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

const createRows = (userProfile) => {
  const htmlStack = []
  let currentRow = []
  let i = 0

  const sortedKeyEntries = Object.entries(userProfile).sort((e1, e2) => {
    const [k1, v1] = e1
    const [k2, v2] = e2

    if (v1?.length < v2?.length) {
      return -1
    }
    if (v1?.length > v2?.length) {
      return 1
    }
    return 0
  })

  for (const [key, value] of sortedKeyEntries) {
    // Do not insert 'ignored' keys
    if (IGNORED_KEYS.includes(key) || !value) {
      continue
    }
    const cleanedKey = toTitleCase(key.replace('_', ' '))
    const component = (
      <Col md="5" key={key} className="my-auto mx-auto">
        <FormGroup>
          <label>{cleanedKey}</label>
          <Input
            disabled
            style={{
              cursor: 'default',
              paddingLeft: 8,
            }}
            defaultValue={value}
            placeholder={cleanedKey}
            type={value?.length > 25 ? 'textarea' : 'text'}
          />
        </FormGroup>
      </Col>
    )

    if (i !== 0 && i / 4 === 0) {
      htmlStack.push(wrapWithRow(currentRow, i))
      currentRow = []
    } else {
      currentRow.push(component)
    }
    i++
  }

  if (currentRow.length > 0) {
    htmlStack.push(wrapWithRow(currentRow, i))
  }

  return htmlStack
}

const getUserProfileImage = (userProfile) =>
  userProfile?.profile_picture
    ? userProfile.profile_picture
    : require('assets/img/lingroupcover.jpg').default

const GoBack = () => (
  <a
    href={window.location}
    style={{ width: 20, position: 'absolute', marginLeft: 48, marginTop: 48 }}
    onClick={() => {}}
  >
    <span className="btn-neutral">
      <i className="ni ni-bold-left px-2 py-8 mb-5" />
    </span>
  </a>
)

const User = ({ userProfile }) => {
  const { t } = useTranslation()
  return (
    <>
      <div className="content">
        <Row>
          <Col md="8">
            <Card className="card-user">
              <GoBack />
              <CardBody>
                <div className="author">
                  <a href="https://facebook.com" target="_blank">
                    <img
                      alt="..."
                      className="avatar border-gray bg-white"
                      src={getUserProfileImage(userProfile)}
                      style={{
                        marginTop: '75px',
                        marginBottom: 0,
                        padding: 4,
                        zIndex: '1',
                        borderWidth: '3px',
                        borderColor: 'rgba(223, 125, 145, 0.75)',
                        objectFit: 'contain',
                      }}
                    />
                    <h5
                      className="title"
                      style={{ color: 'rgb(111, 158, 237)', marginBottom: 0 }}
                    >
                      {userProfile.name}
                    </h5>
                  </a>
                  <div style={{ margin: 0 }}>
                    <Badge value={userProfile.status} backgroundColor={getStatusOptionColor(userProfile.status)} />
                    <p className="small text-center">
                      <i> {t('verifyPortal.found')}</i>
                    </p>
                  </div>
                </div>
                {createRows(userProfile)}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default User
