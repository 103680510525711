/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://4ix3z5oumvahvbfrzulo6bchsm.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-2:ef951070-1fc4-4344-938f-daedfda56f3f",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_H10mZp5G0",
    "aws_user_pools_web_client_id": "1phlpmkr3b87mb1igvm5v5k6p6",
    "oauth": {
        "domain": "verifind-staging.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.app.verifind.us/auth/login/,http://localhost:3000/auth/login/",
        "redirectSignOut": "https://www.app.verifind.us/auth/login/,http://localhost:3000/auth/login/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
