import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "./locales/en/translation.json";
import vn from "./locales/vn/translation.json";

const translationResources = {
  en: {
    translation: en,
  },
  vn: {
    translation: vn,
  },
  vi: {
    translation: vn,
  },
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: translationResources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    load: "languageOnly",
    saveMissing: true,
  });

export default i18n;
