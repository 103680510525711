export const STATUS_OPTIONS = [
  {
    label: 'Active'.toUpperCase(),
    backgroundColor: 'green',
  },
  {
    label: 'Inactive'.toUpperCase(),
    backgroundColor: 'gray',
  },
  {
    label: 'Revoked due to violation'.toUpperCase(),
    backgroundColor: 'red',
  },
  {
    label: 'Hoạt Động'.toUpperCase(),
    backgroundColor: 'green',
  },
  {
    label: 'Không Hoạt Động'.toUpperCase(),
    backgroundColor: 'gray',
  },
  {
    label: 'Cấm Bán'.toUpperCase(),
    backgroundColor: 'red',
  },
];

export const getStatusOptionColor = (label) => STATUS_OPTIONS.find(option => option.label === label).backgroundColor;