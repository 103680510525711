/*
=========================================================
* VeriFind - v1.0.0
=========================================================
*/

import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";import { CopyBlock, dracula } from "react-code-blocks";
import { useTranslation } from 'react-i18next';
import VerifyPortal from './VerifyPortal'

function Dashboard() {
  const widgetCode =
    `<div style="width: 900px; height: 800px">\n  <iframe src="https://www.app.verifind.us/verify"\n         scrolling="no" height="100%" width="100%"\n         style="overflow: hidden; margin: 0,\n                     border-radius: 20px; background: transparent;\n                     border: none; padding: 2px;">\n  </iframe>\n</div>`;
    const { t } = useTranslation();
  return (
    <>
      <div className="content">
        <Row>
          <Col md="8">
            <Card>
              <CardHeader>
                <CardTitle tag="h5"> {t("dashboardScreen.widgetpreview")} </CardTitle>
                <p className="card-category">
                  {t("dashboardScreen.widgetpreviewText")}
                </p>
              </CardHeader>
            </Card>
          </Col>
        </Row>
        <Row>         
            <VerifyPortal />
        </Row>
        <Row>
          <Col md="8">
            <Card className="card-chart">
              <CardHeader>
                <CardTitle tag="h5"> {t("dashboardScreen.integrateWidgetText")} </CardTitle>
                <p className="card-category">
                  {t("dashboardScreen.embedHtmlText")}
                </p>
              </CardHeader>
              <CardBody>
                <CopyBlock
                  text={widgetCode}
                  language={"html"}
                  startingLineNumber={0}
                  theme={dracula}
                />
              </CardBody>
              <CardFooter>
                <hr />
                <div className="card-stats">
                  <i className="fa fa-check" /> {t("dashboardScreen.copyCodeText")}
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
