import faker from 'faker';
import { useTranslation } from 'react-i18next';


export function shortId() {
  return '_' + Math.random().toString(36).substr(2, 9);
}

export function randomColor() {
  return `hsl(${Math.floor(Math.random() * 360)}, 95%, 90%)`;
}
export function makeData(count, t) {
  let data = [];
  let options = [];
  for (let i = 0; i < count; i++) {
    let row = {
      identifier: faker.mersenne.rand(),
      name: faker.name.firstName(),
      email: faker.internet.email(),
      memberSince: faker.date.future().getUTCFullYear(),
      status: t("statusBadgeComponent.status"),
      expertiseLevel: t("expertLevelComponent.expertLevel"),
    };
    options.push({ label: row.music, backgroundColor: randomColor() });

    data.push(row);
  }

  let expertiseLevelOptions = [];
  expertiseLevelOptions.push({ label: t("expertLevelComponent.beginner"), backgroundColor: 'green' });
  expertiseLevelOptions.push({ label:t("expertLevelComponent.productExpert"), backgroundColor: 'blue' });
  expertiseLevelOptions.push({ label: t("expertLevelComponent.productMentor"), backgroundColor: 'orange' });
  expertiseLevelOptions.push({ label: t("expertLevelComponent.wealthCreator"), backgroundColor: 'pink' });

  let statusOptions = [];
  statusOptions.push({ label: t("statusBadgeComponent.active"), backgroundColor: 'green' });
  statusOptions.push({ label: t("statusBadgeComponent.inactive"), backgroundColor: 'gray',});
  statusOptions.push({ label: t("statusBadgeComponent.revoked"), backgroundColor: 'red' });

  let columns = [
    {
      id: 'action',
      label: 'Action',
      accessor: 'action',
      width: 100,
      dataType: DataTypes.SELECT,
      options: [],
    },
    {
      id: 'identifier',
      label: 'Id',
      accessor: 'identifier',
      width: 100,
      dataType: DataTypes.NUMBER,
      options: [],
    },
    {
      id: 'name',
      label: t("manageScreen.nameText"),
      accessor: 'name',
      minWidth: 200,
      dataType: DataTypes.TEXT,
      options: [],
    },
    {
      id: 'status',
      label: t("statusBadgeComponent.status"),
      accessor: 'status',
      dataType: DataTypes.SELECT,
      width: 250,
      options: statusOptions,
    },
    {
      id: 'expertiseLevel',
      label: t("expertLevelComponent.expertLevel"),
      accessor: 'expertiseLevel',
      dataType: DataTypes.SELECT,
      width: 250,
      options: expertiseLevelOptions,
    },
    {
      id: 'memberSince',
      label: t("manageScreen.memberSinceText"),
      accessor: 'memberSince',
      dataType: DataTypes.TEXT,
      width: 150,
      options: [],
    },
    {
      id: 'email',
      label: 'Email',
      accessor: 'email',
      width: 300,
      dataType: DataTypes.TEXT,
      options: [],
    },
    {
      id: 999999,
      width: 20,
      label: '+',
      disableResizing: true,
      dataType: 'null',
    },
  ];
  return { columns: columns, data: data, skipReset: false };
}

export const ActionTypes = Object.freeze({
  ADD_OPTION_TO_COLUMN: 'add_option_to_column',
  ADD_ROW: 'add_row',
  UPDATE_COLUMN_TYPE: 'update_column_type',
  UPDATE_COLUMN_HEADER: 'update_column_header',
  UPDATE_CELL: 'update_cell',
  ADD_COLUMN_TO_LEFT: 'add_column_to_left',
  ADD_COLUMN_TO_RIGHT: 'add_column_to_right',
  DELETE_COLUMN: 'delete_column',
  DELETE_ROW: 'delete_row',
  ENABLE_RESET: 'enable_reset',
});

export const DataTypes = Object.freeze({
  NUMBER: 'number',
  TEXT: 'text',
  SELECT: 'select',
});
