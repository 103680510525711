/*

=========================================================
* VeriFind - v1.0.0
=========================================================

*/
import React, { useState, useEffect, Text } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Form,
  InputGroupAddon,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { ActionTypes } from "../components/EditableTable/utils";
import { DataTypes } from "../components/EditableTable/utils";
import { listVerifiedUsers } from "../graphql/queries";
import {
  createVerifiedUser,
  forceDeleteVerifiedUser,
  updateVerifiedUser,
} from "../graphql/mutations";
import removeItemFromArray from "../helpers/utility";
import EditableTable from "../components/EditableTable";
import ReactFileReader from "react-file-reader";
import XLSX from "xlsx";
import ls from "local-storage";
import { STATUS_OPTIONS } from "helpers/status";

const UPLOAD_CSV = "UPLOAD_CSV";
const UPLOAD_USER = "UPLOAD_USER";
const DELETE_CONFIRMATION = "DELETE_USER";
const TARGET_VERIFIED_USER_COLUMNS = [
  ["Tên", "name"],
  ["Mã số", "id"],
  ["Địa chỉ kinh doanh", "address"],
  ["Trạng thái", "status"],
  ["Tham gia từ", "member since"],
  ["SĐT", "contact"],
  ["Ảnh", "profile picture"],
];

const toJson = (workbook) => {
  var result = {};
  workbook.SheetNames.forEach(function (sheetName) {
    var roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
      header: 1,
    });
    if (roa.length) result[sheetName] = roa;
  });
  return JSON.stringify(result, 2, 2);
};

const processExcel = (data) => {
  var workbook = XLSX.read(data, {
    type: "binary",
  });
  data = toJson(workbook);
  return data;
};

const reduceCSVPayload = (data) => {
  data = [].concat.apply([], Object.values(JSON.parse(data)));
  if (data?.length < 2) {
    return null;
  }

  const newVerifiedUsers = [];
  const columns = data[0];
  const columnIndices = columns?.map((csvColumn, index) => {
    let rindex = {};
    TARGET_VERIFIED_USER_COLUMNS.forEach((columnType) => {
      columnType = columnType.map((type) => type.toLowerCase().trim());
      if (columnType.includes(csvColumn.toLowerCase())) {
        rindex = { type: columnType[1], value: csvColumn, index: index };
      }
    });
    return rindex;
  });

  for (let row = 1; row < data?.length; row++) {
    let selectedRow = data[row];
    newVerifiedUsers.push({
      organization_id:
        selectedRow[columnIndices.find((el) => el.type === "id")?.index],
      email:
        selectedRow[columnIndices.find((el) => el.type === "email")?.index],
      name: selectedRow[columnIndices.find((el) => el.type === "name")?.index],
      address:
        selectedRow[columnIndices.find((el) => el.type === "address")?.index],
      member_since:
        selectedRow[
          columnIndices.find((el) => el.type === "member since")?.index
        ],
      contact:
        selectedRow[columnIndices.find((el) => el.type === "contact")?.index],
      status:
        selectedRow[
          columnIndices.find((el) => el.type === "status")?.index
        ]?.toUpperCase(),
      profile_picture:
        selectedRow[
          columnIndices.find((el) => el.type === "profile picture")?.index
        ],
    });
  }
  return newVerifiedUsers;
};

const handleCSVUpload =
  (setIsLoading, addCSVUsers) => (data, fileInfo, originalFile) => {
    setIsLoading(true);
    if (data?.fileList?.length) {
      try {
        var r = new FileReader();
        r.onload = (e) => {
          var contents = processExcel(e.target.result);
          addCSVUsers(reduceCSVPayload(contents));
        };
        let jsonBase64 = data?.base64;
        let index = jsonBase64.indexOf(",");
        let encodedString = jsonBase64.substr(index + 1);
        const byteCharacters = window.atob(encodedString);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const dataArray = new Uint8Array(byteNumbers);
        const blob = new Blob([dataArray], {
          type: "application/octet-stream",
        });
        r.readAsBinaryString(blob);
      } catch (e) {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

const VFInputGroup = ({ t, setNewVerifiedUser, newVerifiedUser }) => {
  const setStateField = (fieldKey, newValue) => {
    setNewVerifiedUser({
      ...newVerifiedUser,
      ...{
        [fieldKey]: newValue?.length > 0 ? newValue : null,
      },
    });
  };

  const createUserGroup = (
    <FormGroup>
      <label>{t("adminScreen.name")}</label>
      <Input
        placeholder="Name"
        type="name"
        onChange={(e) => setStateField("name", `${e.target.value}`)}
      />
      <br />

      <label>{t("adminScreen.profilePicture")}</label>
      <Input
        placeholder="https://cdn.britannica.com/Ho-Chi-Minh-1957.jpg"
        type="link"
        onChange={(e) => setStateField("profile_picture", `${e.target.value}`)}
      />
      <br />

      <label>{t("adminScreen.contact")}</label>
      <Input
        placeholder={t("adminScreen.contact")}
        type="contact"
        onChange={(e) => setStateField("contact", `${e.target.value}`)}
      />
      <br />

      <label>{t("adminScreen.address")}</label>
      <Input
        placeholder={t("adminScreen.address")}
        type="address"
        onChange={(e) => setStateField("address", `${e.target.value}`)}
      />
      <br />

      <label>{t("adminScreen.ID")}</label>
      <Input
        placeholder="ID"
        type="id"
        onChange={(e) => setStateField("organization_id", `${e.target.value}`)}
      />
      <br />

      {/* <label>
        {t("adminScreen.status")}
      </label>
      <Input placeholder={t("adminScreen.status")} type="status" onChange={(e) => setStatus(`${e.target.value}`)} />
      < br />

      <label>
        {t("adminScreen.expertise")}
      </label>
      <Input placeholder={t("adminScreen.expertise")} type="expertise" onChange={(e) => setExpertise(`${e.target.value}`)} />
      < br /> */}
    </FormGroup>
  );
  return createUserGroup;
};

const reduceVerifiedUser = (verifiedUser, organizationId) => {
  return {
    id: verifiedUser?.id,
    status: verifiedUser?.status?.toUpperCase(),
    address: verifiedUser?.address,
    member_since: verifiedUser?.member_since,
    contact: verifiedUser?.contact,
    email: verifiedUser?.email,
    name: verifiedUser?.name,
    profile_picture: verifiedUser?.profile_picture,
    organizationID: organizationId,
    organization_id: verifiedUser?.organization_id,
    version: verifiedUser?._version,
  };
};

const addVerifiedUser =
  (
    newVerifiedUser,
    setNewVerifiedUser,
    setIsLoading,
    setModal,
    verifiedUsers,
    setVerifiedUsers
  ) =>
  async () => {
    const organizationId = ls.get("organization_id");
    setIsLoading(true);
    setModal(null);
    try {
      const createVerifiedUserResponse = await API.graphql(
        graphqlOperation(createVerifiedUser, {
          input: reduceVerifiedUser(newVerifiedUser, organizationId),
        })
      );
      verifiedUsers.push({
        ...reduceVerifiedUser(newVerifiedUser, organizationId),
        ...{
          createdAt: Date.now(),
          id: createVerifiedUserResponse?.data?.createVerifiedUser?.id,
        },
      });
      setVerifiedUsers(verifiedUsers);
      setNewVerifiedUser(null);
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

const FormModal = ({
  modalState,
  setModalState,
  className,
  verifiedUsers,
  setVerifiedUsers,
  setIsLoading,
  deleteUserCallback,
  isLoading,
  t,
}) => {
  const toggle = () => setModalState(null);
  const visibility = modalState != null;
  const [newVerifiedUser, setNewVerifiedUser] = useState({});
  const [csvUsers, setCSVUsers] = useState([]);
  const organizationId = ls.get("organization_id");

  useEffect(() => {
    const mergedUsers = [].concat.apply(
      [],
      Object.values([verifiedUsers, csvUsers])
    );
    setVerifiedUsers(mergedUsers);
    setIsLoading(true);
    const promises = [];
    csvUsers?.forEach((csvUser) => {
      const graphQLfetchPromise = API.graphql(
        graphqlOperation(createVerifiedUser, {
          input: reduceVerifiedUser(csvUser, organizationId),
        })
      );
      promises.push(graphQLfetchPromise);
    });

    const asyncCallbacks = async () => {
      await Promise.all(promises);
      setIsLoading(false);
      toggle();
    };
    asyncCallbacks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvUsers, setVerifiedUsers]);

  const spinner = (
    <span className="btn-neutral">
      <img
        alt="Loading"
        style={{ width: 600, margin: 0, padding: 0 }}
        src={require("../assets/img/icons/common/loadingbaloon.gif").default}
      />
    </span>
  );

  const isUploadCSV = modalState === UPLOAD_CSV;
  const isAddUser = modalState === UPLOAD_USER;
  let title = "";
  let subtitle = "";
  let buttonTitle = "";
  let actionButton = null;
  switch (modalState) {
    case UPLOAD_CSV:
      title = t("manageScreen.addCSV");
      subtitle = t("manageScreen.subtitleAddCsv");
      buttonTitle = t("manageScreen.addCSV");
      actionButton = (
        <ReactFileReader
          handleFiles={handleCSVUpload(setIsLoading, setCSVUsers)}
          fileTypes={[".xls", ".xlsx", ".csv"]}
          base64={true}
        >
          <Button color="primary">{buttonTitle}</Button>
        </ReactFileReader>
      );
      break;
    case UPLOAD_USER:
      title = t("manageScreen.addUser");
      subtitle = t("manageScreen.subtitleAddUser");
      buttonTitle = t("manageScreen.addUser");
      actionButton = (
        <Button
          color="primary"
          onClick={addVerifiedUser(
            newVerifiedUser,
            setNewVerifiedUser,
            setIsLoading,
            setModalState,
            verifiedUsers,
            setVerifiedUsers
          )}
        >
          {buttonTitle}
        </Button>
      );
      break;
    case DELETE_CONFIRMATION:
      title = t("manageScreen.deleteUser");
      subtitle = t("manageScreen.deleteUserConfirmation");
      buttonTitle = t("manageScreen.deleteUser");
      actionButton = (
        <Button color="primary" onClick={() => deleteUserCallback()}>
          {buttonTitle}
        </Button>
      );
      break;
    default:
  }

  return (
    <div>
      <Modal isOpen={Boolean(visibility)} toggle={toggle} className={className}>
        <ModalHeader
          toggle={toggle}
          style={{ marginBottom: 0, paddingBottom: 0 }}
        >
          {title}
        </ModalHeader>
        {isLoading ? (
          spinner
        ) : (
          <ModalBody style={{ marginBottom: 0, paddingBottom: 0 }}>
            <i className="text-muted" style={{ padding: 10, paddingLeft: 0 }}>
              {subtitle}
            </i>
            <hr />
            {isAddUser && (
              <VFInputGroup
                isUploadCSV={isUploadCSV}
                t={t}
                setNewVerifiedUser={setNewVerifiedUser}
                newVerifiedUser={newVerifiedUser}
              />
            )}
            {isUploadCSV && (
              <ReactFileReader
                handleFiles={handleCSVUpload(setIsLoading, setCSVUsers)}
                fileTypes={[".xls", ".xlsx", ".csv"]}
                base64={true}
              >
                <Row
                  className="justify-content-center"
                  style={{ textTransform: "none" }}
                >
                  <Button color="white">
                    <img
                      alt="Upload CSV or Excel File"
                      height="100vh"
                      width="150wh"
                      style={{
                        marginTop: "5%",
                        marginBottom: "5%",
                        padding: "5px",
                        cursor: "pointer",
                      }}
                      src={require("../assets/img/Upload.png").default}
                    />
                  </Button>
                </Row>
              </ReactFileReader>
            )}
          </ModalBody>
        )}
        <ModalFooter>
          {actionButton}
          <Button color="secondary" onClick={toggle}>
            {t("adminScreen.cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const reduceVerifiedUsers = (payload, organizationId) => {
  return payload?.data?.listVerifiedUsers?.items?.map((verifiedUser) => {
    return reduceVerifiedUser(verifiedUser, organizationId);
  });
};

const verifiedUserActionData = (verifiedUsers, t) => {
  let data = [];
  verifiedUsers?.forEach((verifiedUser) => {
    data.push({
      ...verifiedUser,
      ...{
        action: t("manageScreen.delete"),
      },
    });
  });

  let rowActionOptions = [];
  rowActionOptions.push({
    label: t("manageScreen.delete"),
    backgroundColor: "rgb(111, 158, 237)",
  });

  let expertiseLevelOptions = [];
  expertiseLevelOptions.push({
    label: t("expertLevelComponent.beginner"),
    backgroundColor: "green",
  });
  expertiseLevelOptions.push({
    label: t("expertLevelComponent.productExpert"),
    backgroundColor: "blue",
  });
  expertiseLevelOptions.push({
    label: t("expertLevelComponent.productMentor"),
    backgroundColor: "orange",
  });
  expertiseLevelOptions.push({
    label: t("expertLevelComponent.wealthCreator"),
    backgroundColor: "pink",
  });

  let columns = [
    {
      id: "organization_id",
      label: t("manageScreen.organizationID"),
      accessor: "organization_id",
      width: 200,
      dataType: DataTypes.TEXT,
      options: [],
    },
    {
      id: "name",
      label: t("manageScreen.name"),
      accessor: "name",
      minWidth: 200,
      dataType: DataTypes.TEXT,
      options: [],
    },
    {
      id: "member_since",
      label: t("manageScreen.memberSince"),
      accessor: "member_since",
      minWidth: 75,
      dataType: DataTypes.TEXT,
      options: [],
    },
    {
      id: "status",
      label: t("statusBadgeComponent.status"),
      accessor: "status",
      dataType: DataTypes.SELECT,
      width: 175,
      options: STATUS_OPTIONS,
    },
    {
      id: "address",
      label: t("manageScreen.address"),
      accessor: "address",
      dataType: DataTypes.TEXT,
      width: 500,
      options: [],
    },
    {
      id: "contact",
      label: t("manageScreen.contact"),
      accessor: "contact",
      width: 150,
      dataType: DataTypes.TEXT,
      options: [],
    },
    {
      id: "profile_picture",
      label: t("adminScreen.profilePicture"),
      accessor: "profile_picture",
      width: 150,
      dataType: DataTypes.TEXT,
      options: [],
    },
    {
      id: "action",
      label: t("manageScreen.action"),
      accessor: "action",
      minWidth: 20,
      dataType: DataTypes.SELECT,
      options: rowActionOptions,
    },
  ];
  return { columns: columns, data: data, skipReset: false };
};

let userSearchPool = null;
const organizationId = ls.get("organization_id");

const ManageVerifiedUsers = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [verifiedUsers, setVerifiedUsers] = useState([]);
  const [deletedAdmin, setDeletedAdmin] = useState(null);
  const [deleteCallback, setDeleteCallback] = useState(null);
  const [modalState, setModalState] = useState(null);
  const [editUser, setEditUser] = useState(null);

  const searchVerifiedUsers = async (searchText) => {
    if (!searchText || searchText?.length === 0) {
      setVerifiedUsers(userSearchPool);
      userSearchPool = null;
      return;
    }

    if (!userSearchPool) userSearchPool = verifiedUsers;
    const searchFilter = (user) => {
      let found = false;
      Object.values(user).map((userAttrValue) => {
        if (JSON.stringify(userAttrValue ?? "").includes(searchText)) {
          found = true;
          return;
        }
      });
      return found;
    };

    const filteredUsers = userSearchPool?.filter(searchFilter);
    setVerifiedUsers(filteredUsers);
  };

  const spinner = (
    <span className="btn-neutral">
      <img
        alt="Loading"
        style={{ width: 600, margin: 0, padding: 0 }}
        src={require("../assets/img/icons/common/loadingbaloon.gif").default}
      />
    </span>
  );

  const buttonHandler = (buttonEvent) => {
    const isCSVUpload =
      buttonEvent?.nativeEvent?.srcElement?.className?.includes(
        t("manageScreen.uploadText")
      ) || buttonEvent?.nativeEvent?.srcElement?.id === UPLOAD_CSV;
    const isAddUser =
      buttonEvent?.nativeEvent?.srcElement?.className?.includes(
        t("manageScreen.createUserText")
      ) || buttonEvent?.nativeEvent?.srcElement?.id === UPLOAD_USER;
    const isDeleteUserConfirmation = buttonEvent === DELETE_CONFIRMATION;
    if (isCSVUpload) {
      setModalState(UPLOAD_CSV);
    } else if (isAddUser) {
      setModalState(UPLOAD_USER);
    } else if (isDeleteUserConfirmation) {
      setModalState(DELETE_CONFIRMATION);
    }
  };

  useEffect(() => {
    const fetchVerifiedUsers = async () => {
      setIsLoading(true);
      const verifiedUserPayload = await API.graphql(
        graphqlOperation(listVerifiedUsers, {
          filter: { organizationID: { eq: organizationId } },
          limit: 10000,
        })
      );
      const verifiedUsersReduced = reduceVerifiedUsers(
        verifiedUserPayload,
        organizationId
      );
      setVerifiedUsers(verifiedUsersReduced);
      setIsLoading(false);
    };
    fetchVerifiedUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!deletedAdmin || !verifiedUsers.includes(deletedAdmin)) {
      return;
    }

    const deleteAdminUserHandler = async () => {
      setModalState(null);
      setIsLoading(true);
      try {
        const response = await API.graphql(
          graphqlOperation(forceDeleteVerifiedUser, { id: deletedAdmin?.id })
        );
        if (response) {
          let arrayWithItemRemoved = removeItemFromArray(
            verifiedUsers,
            deletedAdmin
          );
          setVerifiedUsers(arrayWithItemRemoved);
          setDeletedAdmin(null);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };
    if (deletedAdmin) {
      deleteAdminUserHandler();
    }
  }, [verifiedUsers, deletedAdmin]);

  useEffect(() => {
    if (!editUser) {
      return;
    }

    const editVerifiedUser = async () => {
      try {
        const updatePayload = {
          input: {
            id: editUser?.id,
            name: editUser?.name,
            member_since: editUser?.member_since,
            status: editUser?.status?.toUpperCase(),
            address: editUser?.address,
            contact: editUser?.contact,
            profile_picture: editUser?.profile_picture,
            organization_id: editUser?.organization_id,
            _version: editUser?.version,
          },
        };
        const response = await API.graphql(
          graphqlOperation(updateVerifiedUser, updatePayload)
        );
        if (response) {
          setEditUser(null);
        }
        setEditUser(null);
      } catch (e) {
        console.log(e);
      }
    };

    if (editUser) {
      editVerifiedUser();
    }
  }, [editUser]);

  const handlers = {
    [ActionTypes.DELETE_ROW]: (data) => {
      const targetVerifiedUser = verifiedUsers[data.rowIndex];
      setDeleteCallback(() => () => setDeletedAdmin(targetVerifiedUser));
      setModalState(DELETE_CONFIRMATION);
    },
    [ActionTypes.UPDATE_CELL]: (data) => {
      const targetVerifiedUser = verifiedUsers[data.rowIndex];
      targetVerifiedUser[data.column] = data.value;
      setEditUser(targetVerifiedUser);
    },
  };

  return (
    <>
      <FormModal
        visibility={modalState !== null}
        modalState={modalState}
        setModalState={setModalState}
        deleteUserCallback={deleteCallback}
        verifiedUsers={verifiedUsers}
        setVerifiedUsers={setVerifiedUsers}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        t={t}
      />
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="demo-icons">
              <CardHeader>
                <CardTitle tag="h5">{t("manageScreen.importText")}</CardTitle>
                <p className="card-category">
                  {t("manageScreen.uploadAddText")}
                </p>
              </CardHeader>
              <CardBody>
                <Button
                  className="my-2"
                  color="primary"
                  type="button"
                  id={UPLOAD_CSV}
                  onClick={(e) => buttonHandler(e)}
                >
                  {t("manageScreen.uploadText")}{" "}
                  <i className="ni ni-cloud-upload-96 px-4" />
                </Button>
                <br />
                <Button
                  className="my-2"
                  color="primary"
                  type="button"
                  id={UPLOAD_USER}
                  onClick={(e) => buttonHandler(e)}
                >
                  {t("manageScreen.createUserText")}{" "}
                  <i className="ni ni-user-run px-2" />
                </Button>
              </CardBody>
            </Card>
          </Col>
          <Col md="12" className="justify-content-left">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  {t("manageScreen.verifiedPartnersText")}
                  {verifiedUsers?.length > 0 && (
                    <a
                      style={{
                        fontSize: 14,
                        marginLeft: 12,
                        fontStyle: "italic",
                        color: "rgba(0,0,0,0.50)",
                      }}
                    >
                      ({verifiedUsers?.length})
                    </a>
                  )}
                </CardTitle>

                <Form className="navbar-search navbar-search-light form-inline mr-6 d-none d-md-flex">
                  <FormGroup className="mb-0">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText className="mx-3">
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder={t("manageScreen.search")}
                        type="text"
                        onChange={(e) =>
                          searchVerifiedUsers(`${e.target.value}`)
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                </Form>
              </CardHeader>
              <CardBody>
                {isLoading ? (
                  spinner
                ) : (
                  <EditableTable
                    handlers={handlers}
                    key={"tableload-" + verifiedUsers?.length}
                    style={{
                      textAlign: "center",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    data={verifiedUserActionData(verifiedUsers, t)}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ManageVerifiedUsers;
