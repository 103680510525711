import React from 'react';

const Badge = ({ value, backgroundColor, classNames }) => {
  return (
    <span
      className={"font-weight-400 d-inline-block border-radius-sm text-transform-capitalize " + classNames}
      style={{
        backgroundColor: backgroundColor,
        color: 'white',
        padding: '2px 6px',
      }}
    >
      {value}
    </span>
  );
}

export default Badge;