/*!

=========================================================
* VeriFind - v1.0.0
=========================================================
*/

import Dashboard from "views/Dashboard.js";
import ManageVerifiedUsers from "views/ManageVerifiedUsers.js";
import ManageAdminUsers from "views/ManageAdminUsers.js";
import UserPage from "views/User.js";
import Login from "views/Login.js";
import Register from "views/Register.js";
import Logout from "views/Logout.js";

var routes = (t) => [
    {
      path: "/dashboard",
      name: `${t('dashboardScreen.dashboardText')}`,
      icon: "nc-icon nc-bank",
      component: Dashboard,
      layout: "/admin",
    },
    {
      path: "/manage",
      name: `${t('manageScreen.manageText')}`,
      icon: "nc-icon nc-atom",
      component: ManageVerifiedUsers,
      layout: "/admin",
    },
    {
      path: "/user-page",
      name: `${t('adminScreen.adminTabText')}`,
      icon: "nc-icon nc-single-02",
      component: ManageAdminUsers,
      layout: "/admin",
    },
    {
      path: "/sign-out",
      name: `${t('MenuScreen.signoutText')}`,
      icon: "nc-icon nc-air-baloon",
      component: Logout,
      layout: "/admin",
    },
    {
      path: "/login",
      name: "Login",
      icon: "ni ni-key-25 text-info",
      component: Login,
      layout: "/auth",
    },
    {
      path: "/register",
      name: "Register",
      icon: "ni ni-circle-08 text-pink",
      component: Register,
      layout: "/auth",
    },
];

export default routes;
