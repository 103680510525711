/*

=========================================================
* VeriFind - v1.0.0
=========================================================

*/

import { Button, Card, CardHeader, CardBody, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Input, Col } from 'reactstrap';

import { Auth, API, graphqlOperation } from 'aws-amplify';
import ls from 'local-storage';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { createOrganization, createAdminUser } from '../graphql/mutations';
import { listAdminUsers } from '../graphql/queries';

import '../../src/assets/scss/paper-dashboard/auth/_login.scss';

const STEP = {
	SIGN_IN: 0,
	CHOOSE_FLOW: 1,
	REQUEST_ACCESS: 2,
	CREATE_COMPANY: 3,
};

const reduceUser = (user) => {
	const jwtToken = user.signInUserSession.accessToken.jwtToken;
	const expiry = user.signInUserSession.accessToken.payload.exp;
	const email = user.signInUserSession.idToken.payload.email;
	const name = user.signInUserSession.idToken.payload.name;
	return {
		jwtToken,
		expiry,
		email,
		name,
	};
};

const verificationPortalCreator = async (company) => {
	try {
		const userName = ls.get('name');
		const userEmail = ls.get('email');
		ls.set('company', company);
		const org = await API.graphql(graphqlOperation(createOrganization, { input: { name: company, org_admin_email: userEmail } }));
		const newOrgId = org?.data?.createOrganization?.id;
		const adminResponse = await API.graphql(graphqlOperation(createAdminUser, { input: { email: userEmail, name: userName, organizationID: newOrgId } }));
		const adminId = adminResponse?.data?.createAdminUser?.id;
		if (adminId) {
			ls.set('id', adminId);
			ls.set('organization_id', newOrgId);
			window.location = '/admin/dashboard/';
		} else {
			console.log('Unable to create admin organization/account from verificationPortalCreator');
			window.location = '/';
		}
	} catch (err) {
		console.log('Error creating organization:', err);
		window.location = '/';
	}
};

const GoBack = ({ setSignUpStep }) => (
	<a
		href="#reset"
		style={{ width: 10, position: 'absolute' }}
		onClick={() => {
			ls.set('attemptedLogin', false);
			setSignUpStep(STEP.SIGN_IN);
		}}
	>
		<span className="btn-neutral">
			<i className="ni ni-bold-left px-2 mb-5" />
		</span>
	</a>
);

const CreateAccount = ({ signUpStep, setSignUpStep, setIsLoading, t }) => {
	const [company, setCompany] = useState(null);
	switch (signUpStep) {
		case STEP.CHOOSE_FLOW:
			return (
				<>
					<CardHeader>
						<div className="text-danger text-center">
							<small>{t('loginScreen.notAssociatedText')}</small>
						</div>
					</CardHeader>

					<CardBody>
						<GoBack setSignUpStep={setSignUpStep} />

						<div className="text-center mt-2 mb-3">
							<b>{t('loginScreen.requestAccessText')}</b>
							<br />
							<b>{t('loginScreen.createNewText')}</b>
						</div>
						<div className="text-center">
							<Button color="primary" type="button" onClick={() => setSignUpStep(STEP.REQUEST_ACCESS)}>
								{t('loginScreen.requestText')}
							</Button>
						</div>
						<div className="text-center">
							<Button color="primary" type="button" onClick={() => setSignUpStep(STEP.CREATE_COMPANY)}>
								{t('loginScreen.createText')}
							</Button>
						</div>
					</CardBody>
				</>
			);

		case STEP.REQUEST_ACCESS:
			return (
				<>
					<CardHeader>
						<GoBack setSignUpStep={setSignUpStep} />
					</CardHeader>
					<CardBody>
						<div className="text-center mt-2 mb-3 my-3">
							<b>{t('loginScreen.contactAdminText')}</b>
						</div>
					</CardBody>
				</>
			);

		case STEP.CREATE_COMPANY:
			return (
				<>
					<CardHeader className="bg-transparent ">
						<div className="text-muted text-center mt-2 mb-3">
							<small> {t('loginScreen.enterpriseAccountText')} </small>
						</div>
					</CardHeader>
					<CardBody>
						<GoBack setSignUpStep={setSignUpStep} />
						<Form role="form">
							<FormGroup className="mb-3 mx-5">
								<InputGroup className="input-group-alternative">
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<i className="ni ni-paper-diploma px-4" />
										</InputGroupText>
									</InputGroupAddon>
									<Input className="px-3" placeholder="Company" type="company" onChange={(e) => setCompany(`${e.target.value}`)} />
								</InputGroup>
							</FormGroup>
							<div className="text-center">
								<Button
									className="my-4"
									color="primary"
									type="button"
									onClick={async () => {
										setIsLoading(true);
										await verificationPortalCreator(company);
									}}
								>
									{t('loginScreen.verificationPortalText')}
								</Button>
							</div>
						</Form>
					</CardBody>
				</>
			);
		default:
			return null;
	}
};

const CardLogin = ({ signUpStep, setIsLoading, isLoading, t }) => {
	return signUpStep !== STEP.SIGN_IN ? null : (
		<CardHeader className="bg-transparent ">
			<div className="text-muted text-center mt-2 mb-3">
				<small>{t('loginScreen.signinText')}</small>
			</div>
			<div className="mb-2 btn-wrapper text-center">
				<Button
					size="sm"
					outline
					className="btn-neutral"
					color="default"
					onClick={async () => {
						try {
							setIsLoading(true);
							ls.set('attemptedLogin', true);
							await Auth.federatedSignIn({ provider: 'Google' });
						} catch (e) {
							console.log(e);
						}
					}}
				>
					<span className="btn-inner--icon">
						<img alt="Google Workspace" style={{ width: '150px', height: '25px', margin: 0, padding: 0 }} src={require('../assets/img/icons/common/gworkspace1.png').default} />
					</span>
				</Button>
			</div>
		</CardHeader>
	);
};

const Login = () => {
	const [user, setUser] = useState(null); // user that has authed but may not have an organization

	const [userLoaded, setUserLoaded] = useState(false);
	const [signUpStep, setSignUpStep] = useState(STEP.SIGN_IN);
	const [isLoading, setIsLoading] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		const authUser = async () => {
			try {
				setIsLoading(true);
				const sessionPayload = await Auth.currentAuthenticatedUser();
				setUser(reduceUser(sessionPayload));
			} catch (e) {
				await Auth.signOut();
			} finally {
				setIsLoading(false);
			}
		};
		authUser();
	}, []);

	useEffect(() => {
		if (user && !userLoaded) {
			setIsLoading(true);
			setUserLoaded(true);
			ls.set('jwt', user.jwtToken);
			ls.set('expiry', user.expiry);
			ls.set('email', user.email);
			ls.set('name', user.name);
			const verifyAdminUser = async () => {
				try {
					console.log('finding!');
					const adminUserPayload = await API.graphql(graphqlOperation(listAdminUsers, { filter: { email: { eq: user.email } } }));
					const adminUsers = adminUserPayload?.data?.listAdminUsers?.items;
					const isAdminUser = adminUsers.length > 0 && adminUsers[0].email === user.email;
					console.log(adminUsers);
					if (isAdminUser) {
						const adminUser = adminUsers[0];
						ls.set('id', adminUser?.id);
						ls.set('organization_id', adminUser?.organizationID);
						window.location = '/admin/dashboard/';
						return;
					} else {
						setSignUpStep(STEP.CHOOSE_FLOW);
					}
				} catch (e) {
					console.log(e);
				} finally {
					setIsLoading(false);
				}
			};
			verifyAdminUser();
		}
	}, [user, userLoaded]);

	const spinner = (
		<span className="btn-neutral">
			<img alt="Loading" style={{ width: 600, margin: 0, padding: 0 }} src={require('../assets/img/icons/common/loadingbaloon.gif').default} />
		</span>
	);
	
	return (
		<>
			<Col lg="5" md="7">
				<Card className="bg-secondary shadow border-0">
					{isLoading && spinner}
					{!isLoading && signUpStep === STEP.SIGN_IN && <CardLogin signUpStep={signUpStep} setIsLoading={setIsLoading} t={t} />}
					{!isLoading && userLoaded && user && <CreateAccount key={'ca-' + String(isLoading)} signUpStep={signUpStep} setSignUpStep={setSignUpStep} setIsLoading={setIsLoading} user={user} t={t} />}
				</Card>
			</Col>
		</>
	);
};

export default Login;
