const removeItemFromArray = (arr, value) => {
    var index = arr.findIndex((arrObj) => arrObj.id === value.id);
    if (index > -1) {
      arr.splice(index, 1);
      return arr;
    }
    return arr;
};


export default removeItemFromArray;